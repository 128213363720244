import React, { useEffect, useState } from "react";
//STANDARD
import LeadsModule from "../../components/LeadsInputFormNew";
import { MuiThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { alfredoAPIRequesterV2 } from "../../exporters";
import { withStyles } from "@material-ui/core/styles";
import LeadsUser from "../../components/LeadsUser";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { COPY } from "./copy";
import styles from "./styles";
import qs from "query-string";
import url from "url";

//CUSTOM
import { PRIMARY, BACKGROUND_PRIMARY, BACKGROUND_SECONDARY } from "../../theme/DS/colors";
import BackgroundImage from "../../assets/images/backgrounds/background-ds.jpg";
import Logo from "../../assets/images/logos/logo-ds.png";
import theme from "../../theme/DS/ds";

const Layout = (props) => {
	const { classes, children, mobile } = props;

	let root = document.documentElement;
	root.style.setProperty("--color-primary", PRIMARY);

	return (
		<div
			className={classes.container}
			style={{
				backgroundImage: `url(${BackgroundImage})`,
			}}
		>
			<Grid
				className={classes.innerContainer}
				container
				justify="center"
				direction="column"
				style={{
					backgroundImage:
						!mobile &&
						`linear-gradient(-90deg, ${BACKGROUND_PRIMARY} 30%, ${BACKGROUND_SECONDARY} -70%)`,
					backgroundColor: mobile && BACKGROUND_SECONDARY,
				}}
			>
				<div className={classes.compensateHeader}></div>
				{children}
			</Grid>
		</div>
	);
};

const LeadsDS = (props) => {
	const [language, setLanguage] = useState("pt");
	const [loading, setLoading] = useState(false);
	const [mobile, setMobile] = useState(false);
	const [userID, setUserID] = useState(null);
	const [user, setUser] = useState(null);
	const { classes } = props;

	const token = "T/WgplMScshXPvVEWAjLxFPymVmCzm0x";

	const copy = COPY[language];

	useEffect(() => {
		const URL = url.parse(document.URL);
		const userID = qs.parse(URL.search, {
			ignoreQueryPrefix: true,
		}).id;
		if (userID) getUser(userID);
	}, []);

	const matches = useMediaQuery("(max-width:960px)");
	useEffect(() => {
		setMobile(matches);
	}, [matches]);

	const getUser = (userID) => {
		setLoading(true);

		alfredoAPIRequesterV2
			.get({
				urlPath: `leads/users/${userID}`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				setUser(Object.keys(response).length === 0 ? null : response);
				setUserID(userID);
				setLoading(false);
			})
			.catch((err) => setLoading(false));
	};

	const renderLogoDesktop = () => {
		return (
			<img
				className={user ? classes.logoDesktopWithUser : classes.logoDesktop}
				alt="logo-image"
				src={Logo}
			></img>
		);
	};

	const renderUserDesktop = () => {
		return (
			<Grid
				item
				style={{
					margin: "25px 0px",
				}}
			>
				{!loading && user && (
					<Grid container justify="center">
						<Grid item xs={12}>
							<LeadsUser orientation="horizontal" user={user}></LeadsUser>
						</Grid>
					</Grid>
				)}
			</Grid>
		);
	};

	const renderContentText = () => {
		return (
			<Grid container>
				<Grid item xs={12} className={classes.title}>
					{copy.content.title}
				</Grid>
				<Divider className={classes.divider}></Divider>
				<Grid item xs={12} className={classes.subTitle}>
					{copy.content.subTitle}
				</Grid>
			</Grid>
		);
	};

	const renderDisclaimerText = () => {
		return (
			<Grid container style={{ position: "relative", zIndex: 99 }} className={classes.disclaimer}>
				<Grid item xs={12} style={{ lineHeight: "20px" }}>
					{copy.disclaimer}
				</Grid>
			</Grid>
		);
	};

	const renderLeadsForm = (props) => {
		return (
			<Card className={classes.card}>
				<LeadsModule
					token={token}
					lang={language}
					mobile={mobile}
					userID={userID}
					color={PRIMARY}
				/>
			</Card>
		);
	};

	const handleLangChange = (lang) => () => {
		setLanguage(lang);
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Layout {...props} mobile={mobile}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12} className={classes.content}>
						<Grid container>
							<Grid item xs={12} sm={12} md className={classes.leftContent}>
								<Grid container>
									<Grid item xs={12}>
										{renderLogoDesktop()}
										{renderUserDesktop()}
									</Grid>
									<Grid item xs={12}>
										{renderContentText()}
									</Grid>
								</Grid>
							</Grid>
							<Grid item>{renderLeadsForm()}</Grid>
						</Grid>
						{renderDisclaimerText()}
					</Grid>
				</Grid>
			</Layout>
		</MuiThemeProvider>
	);
};
export default withStyles(styles)(LeadsDS);
