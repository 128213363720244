/* const styles = theme => {
  return {
    container: {},
    bodyContainer: {
      padding: "75px 30px",
      [theme.breakpoints.only("xs")]: {
        padding: 0
      }
    },
    button: {
      padding: "1.25rem 0.625rem 1.25rem 3.125rem",
      borderRadius: "0rem",
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: theme.palette.primary.light
      }
    },
    backButton: {
      padding: "1.25rem 3.125rem 1.25rem 0.625rem",
      borderRadius: "0rem",
      backgroundColor: theme.palette.secondary.main,
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: theme.palette.secondary.light
      }
    },
    submitButton: {
      padding: "1.25rem 3.125rem",
      borderRadius: "0rem",
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: theme.palette.primary.light
      }
    },
    titleContainer: {
      padding: "0 .9375rem",
      height: "4.375rem"
    },
    titleContainerUntilStep: {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.l
    },
    titleContainerFurtherStep: {
      border: "1px solid #A9A9A9",
      backgroundColor: "#A9A9A9"
    },
    titleButtonContainer: {
      border: "2px solid white",
      padding: "2px",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    titleButton: {
      fontSize: "2rem",
      color: "white"
    },
    buttonIcon: {
      fontSize: "0.9375rem",
      marginLeft: "30px",
      fontWeight: 400
    },
    backButtonIcon: {
      fontSize: "0.9375rem",
      marginRight: "30px",
      fontWeight: 400
    },
    formContainer: {
      border: "1px solid #DDDDDD",
      padding: "0.9375rem"
    },
    leadTypeSelected: {
      backgroundColor: theme.palette.primary.main,
      padding: "30px 0",
      color: "#f3f5fa",
      height: "100%",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer"
      }
    },
    leadTypeNotSelected: {
      color: theme.palette.primary.main,
      padding: "30px 0",
      height: "100%",
      backgroundColor: "#f3f5fa",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer"
      }
    },
    navTop: {
      "text-align": "center",
      "&:hover": {
        color: "#0054a4",
        cursor: "pointer",
        backgroundColor: "#f3f5fa",
        "box-sizing": "content-box"
      }
    },
    navbarItem: {
      display: "flex",
      padding: ".25rem .5rem",
      textDecoration: "none",
      fontFamily: "Roboto,sans-serif",
      fontSize: "0.85rem",
      fontWeight: 400,
      textAlign: "center",
      height: "130%",
      color: "#4a4a4a",
      "&:hover": {
        color: "#0054a4",
        cursor: "pointer",
        backgroundColor: "#fafafa"
      }
    },
    navbarItemMenu: {
      padding: ".25rem .5rem",
      textDecoration: "none",
      fontFamily: "Roboto,sans-serif",
      fontSize: "0.85rem",
      fontWeight: 400,
      textAlign: "center",
      height: "130%",
      color: "#4a4a4a"
    },
    icon: {
      padding: "5px",
      height: "18px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    footer: {
      backgroundColor: "#363636",
      padding: "25px 25px 10px 25px",
      maxWidth: "100%",
      color: "#FFFFFF"
    }
  };
};

export default styles;
 */

const styles = (theme) => {
  return {
    container: {
      minHeight: "100vh",
      display: "flex",
      position: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    innerContainer: {
      backgroundColor: "transparent",
      transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
    },
    content: {
      maxWidth: "1200px",
      padding: "25px",
      [theme.breakpoints.only("xs")]: {
        padding: "5px",
      },
    },
    leftContent: {
      padding: "0px 120px 0px 0px",
      marginBottom: "25px",
      [theme.breakpoints.only("xs")]: {
        margin: "2.5%",
        padding: "2.5%",
      },
    },
    compensateHeader: {
      [theme.breakpoints.up("md")]: {
        minHeight: 100,
      },
    },
    logoDesktop: {
      width: "40%",
      maxWidth: "450px",
      objectFit: "contain",
      paddingBottom: 40,
      [theme.breakpoints.only("xs")]: {
        width: "40%",
        paddingBottom: 10,
      },
    },
    logoDesktopWithUser: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "30%",
      maxWidth: "250px",
      objectFit: "contain",
      padding: "25px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "200px",
      },
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        width: "100%",
        padding: 0,
        paddingBottom: 10,
      },
    },
    card: {
      boxShadow: "0px 0px 30px 5px rgba(1, 87, 140, 0.2)",
      borderRadius: "10px",
      padding: "25px",
      maxWidth: "450px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.only("xs")]: {
        margin: "5%",
        padding: "5%",
      },
    },
    title: {
      color: "rgba(255, 255, 255, 0.9)",
      fontSize: 20,
      fontWeight: 600,
    },
    divider: {
      width: 100,
      paddingLeft: 0,
      height: "1px",
      marginTop: "15px",
      marginBottom: "15px",
      background: "white",
    },

    subTitle: {
      fontSize: "5rem",
      color: "rgba(255, 255, 255, 1)",
      fontWeight: 500,
      lineHeight: 1.2,
      [theme.breakpoints.down("md")]: {
        fontSize: "4rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "3rem",
      },
    },
    disclaimer: theme.disclaimer
      ? theme.disclaimer
      : {
          padding: "5vh 0px",
          textAlign: "justify",
          fontSize: 14,
          color: "rgba(255, 255, 255, 0.8)",
          lineHeight: 1,
          [theme.breakpoints.only("xs")]: {
            top: "0vh",
            padding: "2.5%",
          },
        },
    customTitle: {
      paddingTop: "40px",
      textAlign: "left",
      color: "#000",
      fontSize: 20,
      lineHeight: 1,
    },
    cSubTitle: {
      textAlign: "left",
      fontSize: 15,
      opacity: 0.5,
      paddingTop: 5,
      paddingBottom: 50,
    },
  };
};

export default styles;
