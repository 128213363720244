const COMPANY_NAME = "Alfredo Leads";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title: "Estime o valor do seu imóvel",
      subTitle: "AVALIE O SEU IMÓVEL EM SEGUNDOS",
    },
    disclaimer:
      "A utilização desta plataforma é gratuita e pretende fornecer um valor expetável de venda     de um imóvel determinado, tendo por base uma análise estatística. Os resultados gerados     são estimativas e fornecem valores meramente indicativos, obtidos a partir da análise     estatística de bases de dados de imóveis e outros indicadores de mercado imobiliário.     Nessa medida, a Decisões E Soluções, não garante qualquer tipo de compensação caso não     sejam alcançados os valores estimados. Existem outros aspectos determinantes para o     apuramento do valor económico de um imóvel pelo que, se pretende uma avaliação     certificada, deverá contactar um Perito Avaliador certificado pela CMVM - Comissão de     Mercado de Valores Mobiliários. Ao subscrever os serviços disponibilizados por esta     plataforma, e consequente consentimento, a Decisões E Soluções procede à recolha e ao     tratamento dos dados pessoais necessários à prestação dos serviços de mediação     imobiliária e a fornecer informação sobre bens imobiliários e oportunidades de negócios,     designadamente através de ações de informação e marketing. A Decisões E Soluções irá     transmitir os dados recolhidos aos consultores imobiliários que compõem a sua rede,     exclusivamente para os fins mencionados. Nos termos da legislação em vigor, a nossa     empresa compromete-se a garantir a proteção, a confidencialidade e a segurança dos dados     pessoais fornecidos. Os titulares dos dados podem a todo o tempo, aceder e/ou solicitar     a retificação ou eliminação dos seus dados pessoais, devendo para o efeito dirigir o     pedido por escrito. Os dados ora recolhidos serão conservados pelo período de tempo     estritamente necessário ao cumprimento dos fins que justificam a sua recolha e apenas pelo período de duração do registo do Subscritor.",
  },
  /*  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title: "Estimate your asset in seconds",
      subTitle: "ESTIMATE YOUR ASSET VALUE",
    },
    disclaimer:
      "The use of this platform is free and aims to provide an expected sale value of a specific property, based on a statistical analysis. The results generated are estimates and provide merely indicative values, obtained from the statistical analysis of real estate databases and other real estate market indicators.",
  }, */
};

/* const copy = {
  languages: ["pt"],
  content: {
    pt: {
  title: "Estime o valor do seu imóvel",
  subtitle: "Receba a sua estimativa de forma imediata e gratuita",
  formHelper: [
    "Preencha todos os campos relativos ao imóvel e os seus detalhes de contacto",
    "Receba a estimativa com o intervalo de preço do imóvel",
    "Contacte um perito avaliador certificado para obter o valor real do imóvel",
  ],
  form: [
    {
  title: "Localização",
      formFields: [
            { name: "Morada ou Cód. Postal", id: "address" },
            {
              id: "assetType",
              name: "Tipo de imóvel",
              options: { house: "Moradia", apartment: "Apartamento" },
            },
            {
              name: "Condição",
              id: "condition",
              options: {
                forRecovery: "Para recuperação",
                inConstruction: "Em construção",
                new: "Novo",
                renovated: "Renovado",
                ruin: "Em ruína",
                used: "Usado",
              },
            },
            {
              name: "Quartos",
              id: "numberOfRooms",
            },
            {
              name: "Área bruta",
              id: "grossArea",
            },
          ],
          button: "Etapa seguinte",
          backButton: "Anterior",
        },
        {
          title: "Características extra",
          formFields: [
            {
              id: "buildingYear",
              name: "Ano de construção",
            },
            {
              id: "energyEfficiency",
              name: "Certificado energético",
            },
            {
              id: "numberOfParkingSpots",
              name: "Lugares de estacionamento",
            },
            {
              id: "numberOfWcs",
              name: "Casas de banho",
            },
            {
              id: "terrainArea",
              name: "Área de terreno",
            },
            {
              id: "usefulArea",
              name: "Área útil",
            },
          ],
          button: "Etapa seguinte",
          backButton: "Anterior",
        },
        {
          title: "Informação adicional",
          characteristics: true,
          formFields: {
            storage: "Arrecadação",
            doubleWindows: "Vidros duplos",
            centralHeating: "Aquecimento central",
            alarm: "Alarme",
            parking: "Estacionamento",
            videoSecurity: "Vídeo-porteiro",
            elevator: "Elevador",
            seaView: "Vista de mar",
            balcony: "Varanda",
            garden: "Jardim",
            basement: "Cave",
            suite: "Suite",
            closedGarage: "Garagem",
            equippedKitchen: "Cozinha equipada",
            electricBlinds: "Estores elétricos",
            pool: "Piscina",
            terrace: "Terraço",
            fireplace: "Lareira",
            airConditioning: "Ar condicionado",
          },
          button: "Etapa seguinte",
          backButton: "Anterior",
        },
        {
          title: "Informação pessoal",
          submit: true,
          formFields: [
           
            {
              id: "name",
              name: "Nome",
            },
            {
              id: "email",
              name: "Endereço de e-mail",
            },
            {
              id: "phone",
              name: "Número de telefone",
            },
            {
              id: "leadType",
              type: "select",
              name: "Eu sou",
              options: {
                buy: "Comprador",
                sell: "Proprietário",
                visit: "Visitante",
                professional: "Profissional do imobiliário",
              },
            },
          
          ],
          button: "Receber estimativa",
          backButton: "Anterior",
        },
        {
          title: "Resultado da estimativa",
          errorMessage: "Ocorreu um erro, por favor tente novamente mais tarde",
          loadingMessage: "Estimativa em curso, por favor aguarde...",
          predictText: "-",
          assetType: { house: "Moradia", apartment: "Apartamento" },
        },
      ],
      errorMessages: {
        required: "Campo obrigatório",
        address: "A morada introduzida tem que ser em Portugal",
        limits: {
          start: "O valor introduzido tem de ser superior a ",
          end: " e inferior a ",
        },
        min: "O valor introduzido tem de ser superior a",
        max: "O valor introduzido tem de ser inferior a",
        invalid: "Valor inválido",
        email: "Email inválido",
        phone: "Número de telefone inválido",
      },
      apiKey: "pfJpjf+DX1JJ0xE3v1cp5UdmKsvaHb/B",
    },
  },
};

export { copy };
 */
